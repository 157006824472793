/*****************************************************
 * node_modules imports
 *****************************************************/
 $custom-file-text: ();
 @import 'node_modules/bootstrap/scss/bootstrap';

 /*****************************************************
  * project imports
  *****************************************************/
 @import 'variables';
 @import 'mixins';
 @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
 /*****************************************************
  * styles
  *****************************************************/
@import './scrollbar';
@import './layout';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


  html, body, app-root, app-game {
    overflow: hidden;
    width   : 100%;
    height  : 100%;
    margin  : 0;
    padding : 0;
    font-size: 16px;
  }
 
  body {
    // background-color: $mainBgColor;
    color: $mainTextColor;
  }

  .engine-wrapper{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;

    #renderCanvas {
      width   : 100%;
      height  : 100%;
      touch-action: none;
    }

    #renderCanvas:focus {
      outline: none;
    }
  }

  .ui-wrapper {
    position: absolute;
    * {
      z-index: 10;
    }
  }

  .cdk-global-scrollblock {
    position: static;
    overflow: hidden !important;
  }
 
  button:focus {
    outline: none;
  }

  .mat-mdc-tooltip {
    font-size: 16px!important;
    white-space: pre-line;
  }

  $width_snackbar: 450px;
  .snackbar-ai-full, .snackbar-ai-splited {
    background: $brandColor;
    opacity: 0.9!important;
    color: #fff;
    font-size: 20px;
    position: absolute;
    bottom: 0;
    width: $width_snackbar;
  }
  .snackbar-ai-splited {
    // adjust % according to cad-view width - ui: .left-sidenav
    left: calc(35% - #{$width_snackbar}*0.5);
  }
  .snackbar-ai-full {
    left: calc(50% - #{$width_snackbar}*0.5);
  }

  .mat-mdc-form-field, .mat-mdc-header-cell, .mat-header-cell, 
  .mat-mdc-card, .mat-mdc-card-content, .mat-tab-label, .mat-tab-link{
    font-size: 14px!important;
  }

  .mdc-text-field--filled {
    background-color: transparent !important;
  }

  .mat-mdc-outlined-button .mdc-button__label+.mat-icon {
    margin-left: -4px !important;
  }

  .mat-drawer-inner-container {
    overflow: hidden !important;
  }

  .mat-mdc-button, .mat-mdc-card-subtitle {
    font-size: 16px;
  }

  .mat-mdc-cell {
    font-size: 15px!important;
    font-weight: 300;
  }

  .generic-form-label {
    font-size: 14px!important;
    font-weight: 300;
  }

  .mat-expansion-panel-header{
    font-size: 17px!important;
  }

  .mat-sort-header-content {
    width: 100%;
  }
  
  .custom-dialog-container .mat-mdc-dialog-container {
    padding: 0 !important;
  }
  // NOTE: we need this line to show calender icon from angular 15 version
  input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
    display: block;
  }
  

  .mat-mdc-checkbox {
    margin-top: 0px !important;
  }

  .mat-mdc-select {
    font-size: 14px;
  }

  .mat-mdc-checkbox label {
    margin-bottom: 0px !important;
  }

  .mat-mdc-radio-button label {
    margin-bottom: 0px !important;
  }

  .mat-mdc-card-header-text {
    width: 100%;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    padding: 0px !important;
  }

  // Below the input there was a bigger area compared to previous angular version (14)
  .mat-mdc-form-field-subscript-wrapper {
    height: 1em;
  }

  .mdc-button {
    letter-spacing: normal;
  }

  .mat-mdc-form-field-infix {
    padding-top: 28px !important;
    padding-bottom: 4px !important;
  }
