@import 'src/styles/variables';

.back-button{
    margin-right: 10px;
  }
 
.titlediv{
  font-size: 20px;
  color: $mainBgColor;
}

// Subtitle for tables, subsections
.subtitle{
  font-size: 15px;
  font-weight: 500;
  color: $darkModeText;
  // margin-left: 8px;
  margin-bottom: 5px;
}

// Colors for active and inactive areas (text, drag&drop) - excluded buttons
.inactivecolor {
  color: $inactiveColor;
  }
.activecolor {
  color: $activeColor;
}

.mat-datepicker-toggle {
  margin-top: -12px !important;
}

.selected-menu {
  background-color: $tileSelection;
}
.deselected-menu {
  background-color: white;
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-handle{
  position: relative;
  top: -70px;
  left: 15px;
}