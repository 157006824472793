// Colors:

    //Brand
    $brandColor: #4091a5ff;
    $brandColorAddibase: #285e6afe;
    $brandColorLight: #dceaeeff;
    $brandColorMedium: #89aeb7ff;
    $brandColorDark: #0B5A6E;

    //Neutral
    $mainBgColor: black;
    $mainBackground: white;
    $greyBackground: lightgrey;
    $homeBackground: #c5dee4ff;
    $tileSelection: lightgrey;
    $greyColorExtraLight: #f5f5f5ff;
    $greyColorLight: #ecececff;
    $greyColorMedium: #c3c3c3ff;
    $greyColorDark: #666666ff;
    $greyColorCardInfo: #e6e6e6ff;
    $greyColorToolbar: #f9fafbff;
    $greyColorMenu: #f2f2f2ff;
    $darkModeText: #424242;
    $primaryTextDark: #333333ff;
    $secondaryTextLight: #666666ff;
    $terciaryTextWhite: white;
    $mainTextColor: #444;
    $hoverTextColor: $greyColorDark;
    $mainText: black;
    $textWhite: white;
    $tileNoSelection: #bed9f270;
    $backgroundGrey: lightgrey;
    $tileGrey: #84898cc9;

    //Action / Alert
    $actionColor: #efa950ff;
    $activeColor: $brandColor;
    $inactiveColor: $greyColorMedium;

    $tileDarkBlue: #0f4c81ff;
    $tileBlue: #79b3ebff;
    $tileRed: #ef706bfe;
    $tileYellow: #f8cc1cfe;
    $tilePurple: #c37bd4fe;
    $progressBar: #b3b3b3ff;

     //Text
   
     $primaryText: #1E1E1E;
     $secondaryText: #424242;
     $terciaryText: #666666ff;
     $mainTextColor: $secondaryText;
     $mainText: $primaryText;
     $textWhite: white;


// needs to be replaced once the mat-card colour is correct
$detailParameterMainColor: $greyColorExtraLight;//#cfd8dc;//#eaf2f5ff;
$backgroundTitleTextCard: $greyColorExtraLight;
$metaDataParameterDetailColor: #cee1e8ff;
$genericFormBorder: lightgrey;
$genericFormBorderBackground: white;

//Borders-radius:
$tileRadiusMax: 20px;
$tileRadiusMed: 7px;
$tileRadiusMin: 5px;
$tileTopBorder: 7px;

//Borders:
$cardBorder: 1px solid $inactiveColor;

//Shadow:
$tileShadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
$elementShadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;

// Typography:
$font-size-xs: 14px;
$font-size-s: 16px;
$font-size-md: 18px;
$font-size-x: 20px;
$font-size-xl: 22px; // not cases yet
$font-size-xxl: 24px;

// $font-weigth-ligth: 300;
// $font-weigth-medium: 400;
// $font-weigth-regular: 500;
// $font-weigth-fett: 700;

$font-weigth-300: 300;
$font-weigth-400: 400;
$font-weigth-500: 500;
$font-weigth-700: 700;




$mainBgColor: black;
$mainTextColor: #444;

$brandColor: #3f51b5;
$mainText: black;
$mainBackground: white;

$textWhite: white;
$tileSelection: #79b3eb30;
$tileNoSelection: #bed9f270;
$backgroundGrey: lightgrey;

$darkModeText: #424242;

$activeColor: black;
$inactiveColor: $backgroundGrey;

$tileGrey: #84898cc9;
$tileDarkBlue: #0f4c81ff;
$tileBlue: #79b3ebff;
$tileRed: #ef706bfe;
$tileYellow: #f8cc1cfe;
$tilePurple: #c37bd4fe;

$tileShadow: rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em;
$tileRadius: 7px;
$tileTopBorder: 7px;

$genericFormBorder: lightblue;
