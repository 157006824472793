$scrollbar-background: transparent;
$scrollbar-thumb-background: #d1d1d1;
$scrollbar-color-active: #3f51b5;
$opacity-hover: 0.65;
$scrollbar-color-hover: rgba(209, 209, 209, $opacity-hover);
$brush-background: #ffffff;

$scrollbar-width: 9px;
$scrollbar-grabarea-width:21px;
$scrollbar-width-x: 7px;
$scrollbar-left-border-width: $scrollbar-grabarea-width - $scrollbar-width;

::-webkit-scrollbar {
    width: $scrollbar-grabarea-width;
  }
  
  // track is the slider on which the thumb is moved. It is not visible.
  ::-webkit-scrollbar-track {
    display: none;
  }
  
  // thumb is element to grab in order to scroll
  ::-webkit-scrollbar-thumb {
    border-left: $scrollbar-left-border-width solid $brush-background;
    border-top: $scrollbar-width-x solid $brush-background;
    background: $scrollbar-thumb-background;
    &:hover {
      background: $scrollbar-color-hover;
    }
    &:active {
      background: $scrollbar-color-active;
    }
  }